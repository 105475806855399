@import url('https://fonts.googleapis.com/css?family=Montserrat:400,700&display=swap');

*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'Montserrat', sans-serif;
}
body{
  background: #E26A2C;
  padding: 0 10px;
}
.wrapper{
  max-width: 1000px;
  width: 100%;
  background: #fff;
  margin: 5px auto;
  padding: 15px;
  border-color: #000;
  border-width: 1px;
  border-style: solid;
}

.noBorder{
  border: none;
}

.title{
  font-size: 32px;
  font-weight: bolder;
  color: #E26A2C;
  text-transform: uppercase;
  text-align: center;
  height: fit-content;
  width: 100%;
  margin: 30px;
}

.wrapper .form{
  width: 100%;
}

.wrapper .form .inputField{
  margin-bottom: 10px;
  display: flex;
  align-items: center;
  width: 100%;
}

.input{
  width: 50%;
  outline: none;
  border: 1px solid #d5dbd9;
  font-size: 12px;
  padding: 8px 10px;
  border-radius: 3px;
  transition: all 0.3s ease;
}

.wrapper .form .inputField .textarea{
  width: 40%;
  height: 125px;
  resize: none;
}

.wrapper .form .inputField .custom_select{
  position: relative;
  width: 100%;
  height: 37px;
}

.wrapper .form .inputField .custom_select:before{
  content: "";
  position: absolute;
  top: 12px;
  right: 10px;
  border: 8px solid;
  border-color: #343534 transparent transparent transparent;
  pointer-events: none;
}

.wrapper .form .inputField .custom_select select{
  -webkit-appearance: none;
  -moz-appearance:   none;
  appearance:        none;
  outline: none;
  width: 100%;
  height: 100%;
  border: 0px;
  padding: 8px 10px;
  font-size: 15px;
  border: 1px solid #d5dbd9;
  border-radius: 3px;
}


.wrapper .form .inputField .input:focus,
.wrapper .form .inputField .textarea:focus,
.wrapper .form .inputField .custom_select select:focus{
  border: 1px solid #E26A2C;
}

.wrapper .form .inputField p{
   font-size: 14px;
   color: #757575;
}
.wrapper .form .inputField .check{
  width: 15px;
  height: 15px;
  position: relative;
  display: block;
  cursor: pointer;
}
.wrapper .form .inputField .check input[type="checkbox"]{
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}
.wrapper .form .inputField .check .checkmark{
  width: 15px;
  height: 15px;
  border: 1px solid #E26A2C;
  display: block;
  position: relative;
}
.wrapper .form .inputField .check .checkmark:before{
  content: "";
  position: absolute;
  top: 1px;
  left: 2px;
  width: 5px;
  height: 2px;
  border: 2px solid;
  border-color: transparent transparent #fff #fff;
  transform: rotate(-45deg);
  display: none;
}
.wrapper .form .inputField .check input[type="checkbox"]:checked ~ .checkmark{
  background: #E26A2C;
}

.wrapper .form .inputField .check input[type="checkbox"]:checked ~ .checkmark:before{
  display: block;
}

.wrapper .form .inputField .btn{
  width: 100%;
   padding: 8px 10px;
  font-size: 15px; 
  border: 0px;
  background:  #E26A2C;
  color: #fff;
  cursor: pointer;
  border-radius: 3px;
  outline: none;
}

.wrapper .form .inputField .btn:hover{
  background: #E26A2C;
}

.wrapper .form .inputField:last-child{
  margin-bottom: 0;
}

.center {
    text-align : center;
}

.flex {
    display : flex;
}

.width100 {
    width : 100%;
}

.fontRed {
    font-size: 14px; 
    font-weight: 800; 
    color : #E02401;
    margin: 30px;
}

.label {
    width: 200px;
    font-size: 14px;
    font-weight: bold;
    color: #000;
}

.centerDiv {
    padding-left: 30px;
    padding-right: 30px;
}

.left {
    text-align: left;
}

@media (max-width:420px) {
  .wrapper .form .inputField{
    flex-direction: column;
    align-items: flex-start;
  }
  .wrapper .form .inputField.terms{
    flex-direction: row;
  }
}